import { SwipeGame } from "./Game";

export interface ResultConfig {
  min: number;
  max: number;
  title?: string;
  image: string;
  text?: string;
}

export class Result {
  config!: ResultConfig;
  game: SwipeGame;

  card!: HTMLDivElement;

  constructor(game: SwipeGame, score: number, configs: ResultConfig[]) {
    this.game = game;

    if (configs.length > 0) this.config = configs[0];

    if (this.game.config.randomResult) {
      const random = Math.round(Math.random() * (configs.length - 1));

      this.config = configs[random];
    } else {
      for (let i = 0; i < configs.length; i++) {
        const u = configs[i];

        if (score >= u.min && score <= u.max) {
          this.config = u;
        }
      }
    }

    if (!this.config) {
      return;
    }

    // this.createCardElement();
  }

  toString() {
    let string = "";
    if (this.config.title) {
      string += this.config.title;
    }
    if (this.config.text) {
      if (string !== "") {
        string += " - ";
      }
      string += this.config.text;
    }
    return string;
  }

  createCardElement() {
    this.card = document.createElement("div");
    this.card.className =
      "flex flex-col w-full h-full rounded-xl bg-white overflow-hidden text-left";

    let img = document.createElement("div");
    img.className = "h-full w-full bg-center bg-cover bg-no-repeat p-2";
    img.style.backgroundImage = `url(${this.config.image})`;
    img.style.backgroundColor = "black";

    this.card.appendChild(img);

    if (this.config.title) {
      let title = document.createElement("div");
      title.className = "p-2 text-left";
      title.innerHTML = this.config.title.replaceAll("\n", "<br/>");
      this.card.appendChild(title);
    }

    if (this.config.text) {
      const text = document.createElement("div");
      text.className = "w-full";
      text.innerHTML = this.config.text.replaceAll("\n", "<br/>");
      this.game.resultContainer.appendChild(text);
    }

    this.game.resultContainer.appendChild(this.card);
  }
}
